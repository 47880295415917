.Box {
  padding: 3rem 3rem;
  width: 100%;
  background-color: #ffffff;
  background-image: url(https://profitbasedocs.blob.core.windows.net/other/footer-bg.png);
}

@media (max-width: 1000px) {
  .Box {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}

.Column {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
}

.Row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;
}

@media (max-width: 1000px) {
  .Row {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.FooterLink,
.FooterLink a {
  color: #271f30;
  font-weight: 700;
  margin-bottom: 20px;
  text-decoration: none;
}

.FooterLink:hover,
.FooterLink a:hover {
  color: white;
  transition: 200ms ease-in;
}

.Heading {
  color: #be3455;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.SocialIconMargin {
  margin-right: 15px;
}
