/* Ustaw szerokość kontenera na 100%, aby dopasowywał się do szerokości ekranu */
.swiper {
  width: 100%;
  height: 50vh; /* Użyj jednostki vh, aby dostosować wysokość do wysokości ekranu */
  max-height: 500px; /* Maksymalna wysokość dla większych ekranów */
}

/* Obrazy w Swiperze */
.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Zmiany wysokości Swiper dla różnych rozmiarów ekranów */

/* Dla ekranów poniżej 768px (np. tablety) */
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination {
    display: none !important;
  }
}

/* Dla ekranów poniżej 480px (np. telefony) */
@media (max-width: 480px) {
  .swiper {
    height: 30vh;
    max-height: 300px;
  }
}
