/* Header Section */
.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0.5;
  padding: 0;
  background: url(https://profitbasedocs.blob.core.windows.net/other/topbg2.jpg)
    center center no-repeat;
  background-color: #ffffff;
  background-size: cover;
}

.intro .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 0.5;
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
  background-size: cover;
}

.intro h1 {
  display: flex;
  font-family: "Raleway", sans-serif;
  justify-content: center;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

header .intro-text {
  padding-top: 250px;
  padding-bottom: 150px;
  text-align: center;
}
