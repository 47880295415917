.blog-wrap {
  max-width: 700px;
  margin: 0 auto;
}

.blog-goBack {
  text-decoration: none;
  font-size: 1rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  margin: 1rem;
}
.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
  border-radius: 40px;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-desc {
  white-space: pre-wrap;
  padding: 1rem;
  margin-top: 1.5rem;
}
