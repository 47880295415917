.about-content {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0rem;
}

.about-content h2 {
  color: #be3455;
  font-size: 2rem;
}

.about-content h1 {
  color: #271f30;
  margin-bottom: 2rem;
}

.about-content h1 span {
  color: #be3455;
}

.about-content p {
  color: #271f30;
  font-weight: 500;
}
a {
  color: black;
  text-decoration: underline;
}

a:hover {
  color: rgb(136, 136, 136);
  text-decoration: underline;
}
