/* Styl głównego kontenera */
.form-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;
}

/* Styl dla grup formularza */
.form-group {
  margin-bottom: 20px;
}

/* Styl dla etykiet */
.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

/* Styl dla pól formularza */
.form-input {
  width: 100%; /* Pola mają szerokość równą kontenerowi */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

/* Styl dla komunikatów o błędach */
.form-error {
  color: #d32f2f;
  font-size: 12px;
  margin-top: 5px; /* Zapobiega nakładaniu się na pole */
  margin-bottom: 10px; /* Dodatkowy odstęp między błędem a kolejnym elementem */
}

/* Styl dla przycisku */
.form-button {
  width: 100%; /* Przycisk również zajmuje pełną szerokość kontenera */
  padding: 12px;
  background-color: #be3455;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-button:hover {
  background-color: #45a049;
}
