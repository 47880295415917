.blogItem-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
}

.blogItem-wrap h3 {
  margin: 1rem 0 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.blogItem-desc {
  white-space: pre-wrap;
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #271f30;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: #0077c2;
  font-size: 1rem;
  font-weight: 600;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 1rem;
  font-weight: 600;
  color: #271f30;
}
