.home-header {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.home-header h2 {
  color: #be3455;
  font-size: 2rem;
}

.home-header h1 {
  font-size: 3rem;
  color: #271f30;
  margin-bottom: 1rem;
}

.home-header h1 span {
  color: #be3455;
}

.home-header p {
  color: #271f30;
  font-weight: 500;
}
