@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: "Montserrat", sans-serif;
}

button,
input {
  font-family: "Montserrat", sans-serif;
}

.container {
  max-width: 1140px;
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
}

.container2 {
  max-width: flex;
  width: 100%;
  margin: 0 auto;
  /* padding: 1rem 0; */
}

/* Set default font size and line height */
body {
  font-size: 16px;
  line-height: 1.5;
}

/* Increase contrast for better readability */
body {
  color: #271f30;
  background-color: #fff;
}

/* Set headings to use the proper hierarchy */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

/* Use a larger font size for main content */
main {
  font-size: 1.125rem;
}

/* Set a maximum width for images */
img {
  max-width: 100%;
  height: auto;
}

/* Add underline on links for better visibility */
a {
  text-decoration: underline;
}

/* Increase line-height for better legibility of body text */
body {
  line-height: 1.6;
}

/* Add padding and margin to make it easier to click/tap */
button,
input {
  padding: 0.5em 1em;
  margin: 0.5em 0;
}

/* Add hover effect to make links more obvious
a:hover {
color: #000;
background-color: #eee;
} */

/* Add focus styling for keyboard accessibility */
a:focus,
button:focus,
input:focus {
  outline: 0;
}

/* Add hover effect for buttons 
button:hover {
background-color: #0077c2;
color: #fff;
}*/

/* Set max-width and word-wrap for long blocks of text */
p {
  max-width: 100%;
  word-wrap: break-word;
}
