.home-header {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.home-header h2 {
  color: #be3455;
  font-size: 2rem;
}

.home-header h1 {
  font-size: 3rem;
  color: #271f30;
  margin-bottom: 1rem;
}

.home-header h1 span {
  color: #be3455;
}

.home-header p {
  color: #271f30;
  font-weight: 500;
}

.flex-iframe {
  flex: 0 0 50%;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.div-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
