/* Team Section */

.section-title {
  text-align: center;
  color: #be3455;
  font-size: 2rem;
}
.section-subtitle {
  text-align: center;
  color: #271f30;
  font-size: 2rem;
}
.team-section {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
#team {
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#team .team-member {
  margin: 0 10px;
  text-align: center;
}

#team .team-member img {
  width: 100%;
  max-width: 240px;
  height: auto;
  border-radius: 50%;
}

#team .team-member .caption {
  padding-top: 10px;
  color: #271f30;
  font-size: 14px;
  line-height: 1.5;
}

#team h4 {
  margin-top: 5px;
  margin-bottom: 5px;
}

#team .team-img {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  object-fit: cover;
}

#team .thumbnail {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

#team .thumbnail .caption {
  padding-top: 10px;
  padding-bottom: 0;
  color: #271f30;
}

#team #row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
}

#team .team {
  flex: 1;
  margin: 0 10px;
  max-width: calc(25% - 20px);
  height: 200px;
}

@media screen and (max-width: 1200px) {
  #team .team {
    max-width: calc(33.33% - 20px);
  }
}

@media screen and (max-width: 992px) {
  #team .team {
    max-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  #team #row {
    flex-direction: column;
  }

  #team .team {
    max-width: calc(100% - 20px);
    margin: 20px 0;
  }
}
